import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Clean Pull from Floor 4-4-4-4 with 105-110% of your max clean`}</p>
    <p>{`then,`}</p>
    <p>{`3 Rounds for time of:`}</p>
    <p>{`20/15 Calorie Assault Bike`}</p>
    <p>{`20 Wall Balls (20/14)`}</p>
    <p>{`20 KBS (53/35)`}</p>
    <p>{`20 Burpees`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Our Annual Thanksgiving Day wod will be at 9:00am this Thursday!
 This workout is free to all so bring a friend!  This will be the only
class of the day. `}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`*`}{`Friday we will reopen for the 10:30 class.  We will be back to
our normal schedule for the rest of the day.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      